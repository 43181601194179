// import Layout from '../components/common/layout';
// import React from 'react';

// export default function NotFound() {
//   return (
//     <Layout>
//       <h1>Page Not Found</h1>
//       <p>Oops, we couldn't find this page!</p>
//     </Layout>
//   );
// }

import { Card, Col, Row } from 'react-bootstrap';

import Footer from '../components/common/footer';
import Layout from '../components/common/layout';
import { Link } from 'gatsby';
import NavigationMenu from '../components/common/navigationMenu';
import React from 'react';

const NotFound = () => {
  return (
    <>
      <Layout>
        <NavigationMenu />

        <Row>
          <Col xs={12} className='error-404'>
            <Card className='comman-card error-comman-card'>
              <div className='error-comman-card-inner'>
                <h1 className='text-purple1'>404</h1>
                <h3 className='text-black1'>
                  Oops! The page your are looking for does not exist.
                </h3>

                <Link to='/' className='btn-primary-custom mr15'>
                  <span>Go Back</span>
                </Link>
              </div>
            </Card>
          </Col>
        </Row>

        <Footer />
      </Layout>
    </>
  );
};

export default NotFound;

// import React from 'react';

// export default function NotFound() {
//   if (typeof window !== 'undefined') {
//     window.location = '/';
//   }

//   return null;
// }
